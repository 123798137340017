<template>
  <div id="projectNav"
       @scroll="scrollEvent"
       ref="projectNav">
    <!--用于设置跳转锚点-->
    <div id="topAnchor"></div>
    <!--返回之前页面-->
    <div class="getBack"
         v-if="userType !== 0 && ProjectType == 2"
         @click="$router.back()">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <!--任务返回项目-->
    <div class="getBack"
         v-if="
        (userType == 0 && ProjectType !== 1) ||
        (userType !== 0 && ProjectType !== 1 && ProjectType !== 2)
      "
         @click="getBack">
      <i class="ivu-icon ivu-icon-ios-arrow-back"></i>
      <span>返回</span>
    </div>
    <!--新建项目-->
    <div class="newProject"
         @click="newProjectFn"
         v-if="
        (ProjectType == 1 && userType == 0) ||
        (ProjectType == 2 && userType == 1)
      ">
      <div class="content">
        <img src="../../../assets/img/Icon/addConstruc.png"
             alt="" />
        <div class="text">
          <h1>{{ user_info.pm_id == 149 ? "新任务" : "新项目" }}</h1>
          <h2>
            {{
              user_info.pm_id == 149
                ? "选择一块区域来新建您的任务"
                : "选择一块区域来新建您的项目"
            }}
          </h2>
        </div>
      </div>
    </div>
    <!--新建任务-->
    <div class="newProject"
         @click="newProjectFn"
         v-if="ProjectType == 3 && userType == 1">
      <div class="content">
        <img src="../../../assets/img/Icon/addConstruc.png"
             alt="" />
        <div class="text">
          <h1>新任务22</h1>
          <h2>选择一块区域来新建您的任务</h2>
        </div>
      </div>
    </div>
    <!--搜索项目-->
    <div class="searchProject"
         v-if="ProjectType !== 3">
      <i class="ivu-icon ivu-icon-ios-search"></i>
      <input type="text"
             v-model="searchMsg"
             :placeholder="placeholder"
             @keyup.enter="searchProjectFn" />
    </div>
    <div class="searchProject"
         v-if="ProjectType == 3">
      <i class="ivu-icon ivu-icon-ios-search"></i>
      <input type="text"
             v-model="searchMsg"
             placeholder="搜索您的任务"
             @keyup.enter="searchProjectFn" />
    </div>
    <!--选择团队-->
    <div class="team"
         v-if="ProjectType == 2 && userType != 2">
      <!--<h1>团队</h1>-->
      <h1>{{ user_info.pm_id == 149 ? "单位" : "团队" }}</h1>
      <CheckboxGroup v-model="teamPitchOn">
        <div class="teamItem"
             v-for="(item, ind) in teamData"
             :key="ind">
          <Checkbox :label="item.name"
                    border
                    style="width: 100%"></Checkbox>
          <div class="teamColor"
               :style="`background-color: ${item.color};`"></div>
        </div>
        <div v-if="teamData.length == 0">
          <h1 style="width: 100%; text-align: center; color: #999999">
            暂无团队
          </h1>
        </div>
      </CheckboxGroup>
    </div>
    <!--项目显示-->
    <div class="ProjectList">
      <h1>最新 <i class="ivu-icon ivu-icon-ios-arrow-round-down"></i></h1>
      <div v-if="userType == 0 || noData">
        <h1 style="width: 100%; text-align: center; color: #999999">
          暂无数据
        </h1>
      </div>
      <!--项目-->
      <div class="ListBox1"
           v-if="ProjectType !== 3">
        <div class="ListItem"
             v-for="(item,idx) in listData"
             :key=idx>
          <div class="ItemLeft"
               @click="IntoheProject(item)">
            <img src="../../../assets/img/Marker/Level_1_project.png"
                 alt=""
                 v-if="ProjectType == 1" />
            <img src="../../../assets/img/Marker/Level_2_project.png"
                 alt=""
                 v-if="ProjectType == 2" />
          </div>
          <div class="ItemContent"
               @click="IntoheProject(item)">
            <h2>{{ item.pm_name }}</h2>
            <h3>{{ item.data_create_time }}</h3>
            <div class="belongToTeam"
                 v-if="ProjectType !== 1">
              <div v-for="(itemColour,idx) in item.teamlist"
                   :key="idx"
                   class="TeamColor"
                   :style="`background-color:${itemColour.comment}`"></div>
            </div>
          </div>
          <div class="ItemRight"
               @click="editShou(item)">
            <img src="../../../assets/img/Marker/compile.png"
                 alt="" />
          </div>
        </div>
      </div>
      <!--项目内的航线建图-->
      <div class="ListBox1"
           v-if="ProjectType == 3">
        <div class="ListItem"
             v-for="(item,idx) in listData"
             :key="idx">
          <div class="ItemLeft"
               @click="IntoheTask(item)">
            <img src="../../../assets/img/Marker/line_green.png"
                 alt=""
                 v-if="item.tasktype == 'airroute'" />
            <img src="../../../assets/img/Marker/pic_red.png"
                 alt=""
                 v-if="item.tasktype == 'figure'" />
          </div>
          <div class="ItemContent"
               @click="IntoheTask(item)">
            <h2>{{ item.filetitle }}</h2>
            <h3>{{ item.data_update_time }}</h3>
            <h3>{{ item.address }}</h3>
          </div>
          <div class="ItemRight">
            <img src="../../../assets/img/Marker/compile.png"
                 alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="goTop"
         @click="scrollToSection"
         ref="goTop">回到顶部</div>
    <!--弹窗区-->
    <!--编辑工程信息-->
    <Modal v-model="editBox"
           width="360"
           class-name="editProject-center-modal"
           @on-cancel="closeEditProject">
      <p slot="header"
         style="color: #409eff; text-align: center">
        <span>编辑工程</span>
      </p>
      <div style="padding: 10px 30px">
        <Form ref="editProjectData"
              :model="editProjectData"
              :rules="editProjectData_ruleInline"
              label-position="top">
          <FormItem label="修改工程名"
                    prop="name">
            <Input v-model="editProjectData.pm_name"></Input>
          </FormItem>
          <FormItem label="修改所属团队"
                    prop="teamList">
            <CheckboxGroup v-model="editProjectData.teamList">
              <div class="teamItem"
                   v-for="(item, ind) in teamData"
                   :key="ind"
                   style="display: inline-block">
                <Checkbox :label="item.id">
                  <div style="display: inline-block">
                    <span :style="`
                                                    display: inline-block;
                                                    background-color: ${item.color};
                                                    width: 10px;
                                                    height: 10px;
                                                    border-radius: 10px;
                                                `"></span>
                    <span>{{ item.name }}</span>
                  </div>
                </Checkbox>
              </div>
              <div v-if="teamData.length == 0">
                <h1 style="width: 100%; text-align: center; color: #999999">
                  暂无团队
                </h1>
              </div>
            </CheckboxGroup>
          </FormItem>
          <FormItem label="是否删除此项目">
            <RadioGroup v-model="ProjectDelete">
              <Radio label="是"></Radio>
              <Radio label="否"></Radio>
            </RadioGroup>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center"
           slot="footer">
        <Button type="info"
                @click="editProject"
                style="width: 200px; letter-spacing: 8px">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'projectNav',
  props: {
    ShowMarker: {
      type: Function,
      default: null,
    },
    shouAirRoute: {
      type: Function,
      default: null,
    },
  },
  data: function () {
    return {
      user_info: {},
      userType: 1,
      newProjectShou: true,
      searchMsg: '',
      teamPitchOn: [],
      teamID: [],
      teamData: [],
      listStatus: true,
      listData: [],
      ProjectType: 0, //1->一级工程,2->二级工程,3->航线建图任务
      Level_1_ID: null,
      Level_2_ID: null,
      noData: false,
      editBox: false, //编辑弹框
      editProjectData: {
        pm_name: '',
        teamList: [],
      },
      editProjectData_ruleInline: {
        pm_name: [{ min: 4, max: 12, message: '项目名称长度 4 - 12个字符' }],
      },
      ProjectDelete: '否',
      editProjectOriginalData: '',
      placeholder: '搜索您的项目',
    };
  },
  mounted() {
    var _this = this;
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'));
    this.placeholder =
      this.user_info.pm_id == 149 ? '搜索您的任务' : '搜索您的项目';
    this.userType = sessionStorage.getItem('userType');
    this.$store.state.ProjectListType = this.userType;
    if (this.userType == 0) {
      //超管登录显示一级工程
      this.Level_1_projectData();
    } else if (this.userType == 1 || this.userType == 2) {
      //工程管理员登录显示二级工程
      this.Level_2_projectData(this.user_info.pm_id);
    }
    // 改变显示项目
    this.$_bus.$off('Into_the_project');
    this.$_bus.$on('Into_the_project', function (pm_id) {
      _this.Level_2_projectData(pm_id);
    });
    //改变显示任务
    this.$_bus.$off('Into_the_task');
    this.$_bus.$on('Into_the_task', function (pm_id) {
      _this.task_Data(pm_id);
    });
  },
  methods: {
    // 返回
    getBack() {
      this.ProjectType = this.ProjectType - 1;
      this.$store.state.ProjectListType = this.$store.state.ProjectListType - 1;
      switch (this.ProjectType) {
        case 1: {
          this.Level_1_projectData();
          break;
        }
        case 2: {
          this.Level_2_projectData(this.Level_1_ID);
          break;
        }
      }
    },
    // 新建项目
    newProjectFn() {
      var _this = this;
      if (this.ProjectType == 1) {
        // console.log("创建一级工程");
        this.$store.state.CreateType = 1;
        this.$router.push('/index/CreateProject');
      } else if (this.ProjectType == 2) {
        // console.log("创建二级工程");
        this.$store.state.CreateType = 2;
        this.$router.push({
          path: '/index/CreateProject',
          query: {
            ID: _this.Level_1_ID,
          },
        });
      } else if (this.ProjectType == 3) {
        // console.log("创建二级工程下的子任务");
        this.$store.state.CreateType = 3;
        this.$router.push({
          path: '/route-specification',
          query: {
            team_id: this.user_info.team_id,
          },
        });
      }

      // const {href} = this.$router.resolve({
      //     name: '新建项目,任务'
      // });
      // window.open(href, '_blank');
    },
    // 搜索项目
    searchProjectFn() {
      var _this = this;
      // console.log("搜索信息",this.searchMsg);
      switch (this.ProjectType) {
        case 1: {
          // 搜索一级工程
          this.$post('/admin/first_pm/pmListPage', {
            pm_name: _this.searchMsg,
          }).then((res) => {
            if (res.data == undefined) {
              _this.$Modal.info({
                title: '提示',
                content: '没有找到此一级工程',
              });
              this.$store.state.ProjectListType = 0;
              return;
            }
            if (res.code == 1) {
              _this.ProjectType = 1;
              _this.listData = res.data;
              // console.log(res);
              // 在地图上显示项目地点
              _this.ShowMarker(_this.listData, _this.ProjectType);
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
          break;
        }
        case 2: {
          // 搜索二级工程
          this.$post('/admin/second_pm/secondPmListPage', {
            pm_first_id: this.Level_1_ID,
            pm_name: _this.searchMsg,
          }).then((res) => {
            if (res.data.pmlist.length == 0) {
              _this.$Modal.info({
                title: '提示',
                content: '没有找到此项目',
              });
              this.$store.state.ProjectListType = 0;
              return;
            }
            if (res.code == 1) {
              _this.ProjectType = 2;
              _this.listData = res.data.pmlist;
              // console.log(res);
              // 在地图上显示项目地点
              _this.ShowMarker(_this.listData, _this.ProjectType);
              this.$store.state.ProjectListType = 1;
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
          break;
        }
        case 3: {
          // 搜索任务
          this.$post('/admin/air_route/airRouteList', {
            pm_id: this.Level_2_ID,
            task_name: _this.searchMsg,
          }).then((res) => {
            // if(res.data==undefined){
            //     _this.$Modal.info({
            //         title: "提示",
            //         content: "没有找到此任务数据"
            //     });
            //     this.$store.state.ProjectListType=1;
            //     return
            // }
            // if(res.code==1){
            //     _this.ProjectType=3;
            //     _this.listData=res.data;
            //     console.log(res);
            //     // 在地图上显示项目地点
            //     _this.ShowMarker(_this.listData,_this.ProjectType);
            //     this.$store.state.ProjectListType=2;
            // }else {
            //     this.$Message.error(res.msg_customer);
            // }
            if (res.code == 1) {
              if (
                res.data.tasklist == undefined ||
                res.data.tasklist.length == 0
              ) {
                this.noData = true;
                if (_this.userType == 1) {
                  _this.$_bus.$emit('clearMap');

                  _this.ProjectType = 3;
                  this.$store.state.ProjectListType = 2;
                  _this.listData = [];
                  return;
                }
                _this.$Modal.info({
                  title: '提示',
                  content: '此项目下暂无任务数据',
                });
                this.$store.state.ProjectListType = 1;
                return;
              }
              // console.log("加载了任务信息");
              _this.ProjectType = 3;
              _this.listData = res.data.tasklist;
              // 在地图上显示项目地点
              _this.ShowMarker(_this.listData, _this.ProjectType);
              this.$store.state.ProjectListType = 2;
              this.noData = false;
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
          break;
        }
      }
      _this.searchMsg = '';
    },
    // 加载一级工程数据
    Level_1_projectData() {
      var _this = this;
      this.$post('/admin/first_pm/pmListPage').then((res) => {
        if (res.code == 1) {
          _this.ProjectType = 1;
          _this.listData = res.data;
          // console.log(res);
          // 在地图上显示项目地点
          _this.ShowMarker(_this.listData, _this.ProjectType);
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 加载二级工程数据
    Level_2_projectData(id) {
      this.getTeamData(id);
      this.Level_1_ID = id;
      var _this = this;
      var pm_id;
      if (this.userType == 0) {
        pm_id = id;
      } else if (this.userType == 1 || this.userType == 2) {
        pm_id = this.user_info.pm_id;
      }
      var team_ids;
      if (this.userType == 2) {
        team_ids = this.user_info.team_id;
      } else {
        team_ids = _this.teamID;
      }
      let data = {
        pm_first_id: pm_id,
        team_ids: team_ids,
      };

      this.$post('/admin/second_pm/secondPmListPage', data).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          if (res.data.pmlist == undefined) {
            // console.log("_this.ProjectType", _this.ProjectType);
            _this.$store.state.ProjectListType = 1;
            return;
          }
          if (res.data.pmlist.length == 0) {
            if (_this.teamPitchOn.length == 0) {
              // console.log( this.userType);
              if (this.userType == 0) {
                _this.$Modal.info({
                  title: '提示',
                  content: '此一级工程下暂无项目',
                });
                this.$store.state.ProjectListType = 0;
              } else {
                _this.ProjectType = 2;
                this.noData = true;
                this.listData = [];
              }
              return;
            } else {
              _this.$Modal.info({
                title: '提示',
                content: '此团队下暂无项目',
              });
              return;
            }
          }
          _this.ProjectType = 2;
          _this.listData = res.data.pmlist;
          // 在地图上显示项目地点
          _this.ShowMarker(_this.listData, _this.ProjectType);
          this.$store.state.ProjectListType = 1;

          this.noData = false;
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 加载团队数据
    getTeamData(id) {
      this.$post('/admin/Team/teamList', {
        pm_first_id: id,
      }).then((res) => {
        // console.log("团队数据",res);
        if (res.code == 1) {
          this.teamData = [];
          res.data.teamlist.forEach((item) => {
            this.teamData.push({
              name: item.team_name,
              color: item.comment,
              id: item.id,
            });
          });
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 加载任务数据
    task_Data(id) {
      var _this = this;
      this.Level_2_ID = id;
      this.$post('/admin/air_route/airRouteList', {
        pm_id: id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          if (res.data.tasklist == undefined || res.data.tasklist.length == 0) {
            if (_this.userType == 1) {
              _this.$_bus.$emit('clearMap');

              _this.ProjectType = 3;
              this.$store.state.ProjectListType = 2;
              _this.listData = [];
              this.noData = true;
              return;
            }
            _this.$Modal.info({
              title: '提示',
              content: '此项目下暂无任务数据',
            });
            this.$store.state.ProjectListType = 1;
            this.noData = true;
            return;
          }
          // console.log("加载了任务信息");
          _this.ProjectType = 3;
          _this.listData = res.data.tasklist;
          // 在地图上显示项目地点
          _this.ShowMarker(_this.listData, _this.ProjectType);
          this.$store.state.ProjectListType = 2;
          this.noData = false;
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 点击列表进入项目
    IntoheProject(item) {
      if (this.$store.state.ProjectListType <= 0) {
        //点击一级工程
        this.$store.state.ProjectListType = 0;
        this.Level_2_projectData(item.pm_id); //加载此一级工程下二级工程信息
      } else if (this.$store.state.ProjectListType == 1) {
        //点击二级工程
        this.task_Data(item.pm_id);
        sessionStorage.setItem('Project2info', JSON.stringify(item));
      } else if (this.$store.state.ProjectListType == 2) {
        //点击任务
        sessionStorage.setItem('ProjectTaskinfo', JSON.stringify(item));
        this.$router.push({
          path: '/create/air-line/application',
        });
      }
    },
    // 点击列表进入任务
    IntoheTask(item) {
      var _this = this;
      // console.log("点击列表进入任务",item);
      sessionStorage.setItem('getTaskData', JSON.stringify(item));
      if (this.$route.query.Status == 2) {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'UpZip',
          },
        });
      } else {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'imgUpload',
          },
        });
      }
    },
    // 监听滚动距离
    scrollEvent() {
      var top = this.$refs.projectNav.scrollTop;
      if (top >= 450) {
        this.$refs.goTop.style.display = 'block';
      } else {
        this.$refs.goTop.style.display = 'none';
      }
    },
    // 回到顶部
    scrollToSection() {
      document
        .getElementById('topAnchor')
        .scrollIntoView({ behavior: 'smooth' });
    },
    // 编辑弹框显示
    // 编辑和删除项目,任务 未完待续========================
    editShou(item) {
      // if (this.userType == 0 || this.userType == 2) {
      //   //超管和团队管理员不允许修改
      //   return;
      // }

      this.editBox = true;
      console.log(item);
      this.editProjectOriginalData = item;
      this.editProjectData.pm_name = item.pm_name;
      if (item.bind_team !== '') {
        this.editProjectData.teamList = [];
        item.teamlist.forEach((item2, ind2) => {
          this.editProjectData.teamList.push(item2.id);
        });

        this.editProjectData.teamList = this.editProjectData.teamList.filter(
          (e) => e
        );
        console.log(this.teamData);
        console.log(this.editProjectData.teamList);
      }
    },
    // 提交编辑项目
    editProject() {
      // console.log("创建一级工程");
      var _this = this;
      this.$refs['editProjectData'].validate((valid) => {
        if (valid) {
          if (this.ProjectDelete == '是') {
            _this
              .$post('/admin/second_pm/secondPmDel', {
                pm_id: this.editProjectOriginalData.pm_id,
              })
              .then((res) => {
                // console.log(res);
                if (res.code == 1) {
                  _this.$Notice.info({
                    title: '提示',
                    desc: res.msg_customer,
                  });
                  if (this.userType == 0) {
                    //超管登录显示一级工程
                    this.Level_1_projectData();
                  } else if (this.userType == 1) {
                    //工程管理员登录显示二级工程
                    this.Level_2_projectData(this.user_info.pm_id);
                  }
                  this.editBox = false;
                } else {
                  _this.$Notice.error({
                    title: '提示',
                    desc: res.msg_customer,
                  });
                }
              });
            return;
          }

          let data = {
            pm_name: this.editProjectData.pm_name,
            pm_id: this.editProjectOriginalData.pm_id,
            start_time: this.editProjectOriginalData.data_create_time,
            location:
              '[' +
              this.editProjectOriginalData.location[0].lng +
              ',' +
              this.editProjectOriginalData.location[0].lat +
              ']',
            pm_type: this.editProjectOriginalData.pm_type,
            team_id: this.editProjectData.teamList,
          };
          // console.log(data);
          _this.$post('/admin/second_pm/secondPmEdit', data).then((res) => {
            // console.log(res);
            if (res.code == 1) {
              _this.$Notice.info({
                title: '提示',
                desc: res.msg_customer,
              });
              if (this.userType == 0) {
                //超管登录显示一级工程
                this.Level_1_projectData();
              } else if (this.userType == 1) {
                //工程管理员登录显示二级工程
                this.Level_2_projectData(this.user_info.pm_id);
              }
              this.editBox = false;
            } else {
              _this.$Notice.error({
                title: '提示',
                desc: res.msg_customer,
              });
            }
          });
        } else {
          this.$Message.error('请正确填写表单');
        }
      });
    },
    // 关闭了编辑弹窗
    closeEditProject() {
      this.ProjectDelete = '否';
      this.editProjectOriginalData = '';
      this.editProjectData.pm_name = '';
    },
  },
  watch: {
    teamPitchOn(val) {
      if (val == []) {
        this.teamID = [];
      } else {
        this.teamID = [];
        val.forEach((item) => {
          this.teamData.forEach((item2) => {
            if (item2.name == item) {
              this.teamID.push(item2.id);
            }
          });
        });
      }
      this.Level_2_projectData(this.Level_1_ID);
    },
  },
};
</script>

<style scoped lang="scss">
#projectNav {
  position: relative;
  padding-bottom: 50px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .getBack {
    background-color: #fff;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    i {
      font-size: 25px;
      color: #333333;
      margin-right: 6px;
      line-height: 50px;
    }
    span {
      font-size: 20px;
      color: #333333;
      line-height: 50px;
    }
  }
  .newProject {
    width: 320px;
    height: 100px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    .content {
      display: flex;
      align-items: center;
      img {
        width: 44px;
        height: 44px;
        margin-right: 8px;
      }
      .text {
        h1 {
          font-size: 24px;
          color: #333333;
          background-color: #fff;
          font-weight: bold;
        }
        h2 {
          font-size: 16px;
          color: #999;
        }
      }
    }
    &:hover {
      border: 1px solid #409eff;
    }
  }

  .searchProject {
    width: 320px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    input {
      width: 100%;
      height: 40px;
      font-size: 16px;
      color: #999;
      border: none;
      background-color: #fafafa;
      border-bottom: 1px solid #dcdee2;
      padding: 0 30px;
      text-align: center;
    }
    input:focus::-webkit-input-placeholder {
      color: transparent;
    }
    i {
      position: absolute;
      top: 10px;
      left: 0;
      font-size: 24px;
      color: #dcdee2;
    }
  }
  .team {
    width: 320px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    h1 {
      font-size: 20px;
      color: #333333;
      margin-bottom: 10px;
    }
    .teamItem {
      position: relative;
      margin-bottom: 8px;
      .teamColor {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 16px;
        height: 16px;
        border-radius: 20px;
      }
    }
  }
  .ProjectList {
    width: 320px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
    h1 {
      font-size: 20px;
      color: #333333;
      margin-bottom: 10px;
      position: relative;
      i {
        position: absolute;
        top: 2px;
        left: 35px;
        font-size: 30px;
        color: #333333;
      }
    }
    .ListItem {
      width: 320px;
      height: 100px;
      background-color: #fff;
      border: 1px solid #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .ItemLeft {
        width: 80px;
        height: 100%;
        position: relative;
        img {
          width: 20;
          height: 32;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .ItemContent {
        width: 210px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 18px;
          color: #333333;
        }
        h3 {
          width: 100%;
          font-size: 14px;
          color: #999;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .belongToTeam {
          .TeamColor {
            float: left;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 4px;
            margin-top: 6px;
          }
        }
      }
      .ItemRight {
        width: 30px;
        height: 100%;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .ListItem:hover {
      border: 1px solid #409eff;
    }
  }
  .goTop {
    width: 80px;
    height: 30px;
    font-size: 12px;
    color: #fff;
    border-radius: 4px;
    background-color: #409eff;
    text-align: center;
    line-height: 30px;
    position: fixed;
    bottom: 20px;
    left: 80px;
    cursor: pointer;
    display: none;
  }
}
#projectNav::-webkit-scrollbar {
  display: none;
}
</style>
